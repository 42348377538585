<template>
  <!--карточка пользователя-->
  <div :id="!this.isEmpty(this.item.id) ? 'item_' + this.item.id : false"
       class="card client-item p-20 pb-0 bg-white border rounded-1"
       :class="{
		 	'border-brd-primary': (!this.item.is_new && !this.item.is_deleted),
		 	'border-success shadow': (this.item.is_new && !this.item.is_deleted),
		 	//'border-danger shadow': (this.item.is_deleted && !this.item.is_new),
		}">
    <!--header-->
    <div class="card-header px-0 border-bottom border-brd-primary pb-20">
      <!--header-top-->
      <div class="d-flex align-items-start mb-3">
        <div class="me-auto">
          <div class="d-flex flex-wrap mb-20">
                        <span v-if="!this.isEmpty(this.item.date_add)" class="text-secondary me-10 mb-1 fs-5">
							{{ this.formatDateTime(this.item.date_add, 'L') }}
						</span>
            <span v-if="!this.isEmpty(this.item.roles)" class="text-secondary me-10 mb-1 fs-5">
							<span v-for="(role, index) in this.item.roles"
                    :key="index"
                    v-text="(index > 0) ? ' / ' + role : role"/>
						</span>
            <span v-if="!this.isEmpty(this.item.types)" class="text-secondary me-10 mb-1 fs-5">
							<span v-for="(role, index) in this.item.types"
                    :key="index"
                    v-text="(index > 0) ? ' / ' + role : role"/>
						</span>
            <span class="text-secondary me-10 mb-1 fs-5" @click="this.copyID(this.item.id)">
							ID {{ this.item.id }}
						</span>
            <span v-if="this.source && !this.partner" class="text-secondary me-10 mb-1 fs-5">
							{{ this.source }}
						</span>
            <span v-else-if="this.source && this.partner" class="text-secondary me-10 mb-1 fs-5">
							{{ this.source }} / {{ this.partner }}
						</span>
            <span v-else-if="!this.source && this.partner" class="text-secondary me-10 mb-1 fs-5">
							{{ this.partner }}
						</span>
          </div>

          <div v-if="this.item.name" class="d-flex align-items-center">
            <div v-if="this.item.is_confirm" class="form-check">
              <input class="form-check-input me-10 rounded-1"
                     type="checkbox"
                     :id="'client_'+this.item.id"
                     :checked="this.is_checked ? true : false"
                     v-model="this.is_checked" />
            </div>
            <span class="fs-3 fw-semi font-semi"
                  for="client"
                  style="text-decoration: underline; max-width: 300px; cursor: pointer;"
                  @click="this.showClientContent()"
                  v-html="this.item.name" />
            <span v-if="this.item.is_hot" class="d-inline-flex ms-1 my-auto">
                        	<img :src="require('@/assets/icons/fire-icon.svg')" alt="HOT" width="24" height="24">
						</span>
            <span v-if="this.item.priority == 1"
                  class="d-inline-flex p-2 ms-1 my-auto bg-raspberry rounded-circle"/>
            <span v-else-if="this.item.priority == 2"
                  class="d-inline-flex p-2 ms-1 my-auto bg-warning rounded-circle"/>
            <span v-else-if="this.item.priority == 3"
                  class="d-inline-flex p-2 ms-1 my-auto bg-ocean rounded-circle"/>
            <span v-else-if="this.item.priority == 4"
                  class="d-inline-flex p-2 ms-1 my-auto bg-success rounded-circle"/>
          </div>
        </div>

        <div v-if="this.item.this_user_can_edit_clients || this.$store.getters.userInfo.permissions.menu_all_clients_edit == 1 || this.$store.getters.userInfo.id == this.item.who_work.user_id">
          <div class="d-flex flex-column" style="margin-right: -1rem;margin-top: -1rem;">
            <router-link :to="'/edit-client?id=' + this.item.id"
                         class="btn">
              <img :src="require('@/assets/icons/options-icon.svg')" alt="Редактировать" width="24" height="24">
            </router-link>
            <button v-if="this.item.is_deleted" type="button"
                    class="btn"
                    alt="Восстановить клиента"
                    data-bs-toggle="modal"
                    :data-bs-target="'#' + this.confirmModalState.fromSection + this.item.id"
                    @click="this.fillConfirmModalState('restoreClient')">
              <img :src="require('@/assets/icons/back-icon.svg')" alt="Восстановить" width="22" height="22">
            </button>
            <button v-else-if="!this.item.is_deleted" type="button"
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#closeClientModal"
                    :data-bs-client-id="this.item.id"
                    alt="Закрыть клиента">
              <img :src="require('@/assets/icons/close-icon.svg')" alt="Закрыть" width="18" height="18">
            </button>
            <button v-if="this.item.is_deleted && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin)" type="button"
                    class="btn"
                    alt="Удалить клиента"
                    @click="this.deleteClient()">
              <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="22" height="22">
            </button>
          </div>
        </div>
      </div>
      <!--end header-top-->

      <div class="row align-items-center">
        <!--header-middle-->
        <div class="col d-flex align-items-center">
          <div class="d-inline-flex"
               role="button"
               data-bs-toggle="modal"
               data-bs-target="#changeStageModal"
               :data-bs-client-id="this.item.id"
               :data-bs-funnel-id="this.item.funnel_id">
            <div v-if="this.is_last_stage" class="crm-btn btn border border-brd-primary icon icon-color-secondary bg-light-gray rounded-3 me-10">
              <img :src="require('@/assets/icons/back-icon.svg')" alt="Этап воронки" width="20" height="20" />
            </div>
            <div v-else class="crm-btn btn border border-brd-primary bg-light-gray rounded-3 me-10">
              <img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="Этап воронки" width="20" height="20" />
            </div>
            <div class="d-flex flex-column justify-content-center">
              <span class="text-font-secondary pt-1 fs-4 pb-1">Этап воронки:</span>
              <span v-if="!this.isEmpty(this.item.stage_name)"
                    class="link border-0"
                    :class="(this.is_last_stage) ? 'text-danger' : ''"
                    :data-stage-id="this.item.stage_id">
								{{ this.item.stage_name }}
								<sup class="badge bg-danger rounded-3" v-if="this.item.is_deleted || this.item.is_canceled || this.item.is_completed">закрыт</sup>
							</span>
              <span v-else class="link border-0"
                    :data-stage-id="this.item.stage_id">
								Новый клиент
							</span>
            </div>
          </div>
        </div>
        <!--end header-middle-->

        <!--header-bottom-->
        <div class="col d-flex flex-row flex-fill mt-3" :class="{'flex-fill': !this.isEmpty(this.tags_filtered)}">
          <div v-if="!this.isEmpty(this.tags_filtered)"
               class="d-flex flex-fill align-items-center ms-0 me-auto mw-60">
            <div class="d-flex border bg-light-gray rounded-circle p-2 me-10">
              <img :src="require('@/assets/icons/tag-icon.svg')" alt="Теги" width="12" height="12">
            </div>
            <ul class="d-inline-flex align-items-center flex-nowrap text-nowrap-mask mb-0 px-0 pe-4">
              <li class="fs-5 text-ocean"
                  v-for="(tag, index) of this.tags_filtered"
                  :key="tag.id">
                <span :data-tag-id="tag.id" v-text="(index > 0) ? ', ' + tag.name : tag.name"/>
              </li>
            </ul>
          </div>
          <div v-if="this.item.is_confirm" class="d-flex flex-shrink-0 ms-auto me-0">
            <a href="javascript:{}" class="btn d-flex align-items-center p-0"
               data-bs-toggle="modal"
               data-bs-section="clients"
               :data-bs-client-id="this.item.id"
               :data-bs-tags-list="JSON.stringify(this.tags)"
               data-bs-target="#setTagsModal">
							<span
                  class="d-flex flex-shrink-0 align-items-center justify-content-center bg-primary rounded-circle me-1"
                  style="width:1.875rem; height:1.875rem;">
								<img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="10"
                     height="10">
							</span>
              <span class="text-font-dark fs-5 ps-2">Добавить тег</span>
            </a>
          </div>
        </div>
        <!--end header-bottom-->
      </div>
    </div>

    <!--content-->
    <div class="card-body px-0 py-20border-bottom border-brd-primary" v-if="isClientContentOpened">
      <div class="d-flex flex-column mb-20">
        <ul class="d-flex flex-column px-0">
          <li v-if="!this.isEmpty(this.item.phones)"
              class="d-flex flex-column mb-3">
						<span v-if="Object.entries(this.item.phones).length > 1"
                  class="mb-1 fs-5 text-font-secondary">Телефоны</span>
            <span v-else
                  class="mb-1 fs-5 text-font-secondary">Телефон</span>
            <ul class="d-flex flex-wrap px-0" style="max-width: 80%;">
              <li v-for="(phone, index) in this.item.phones" :key="index"
                  class="me-20">
                <a :href="'tel:' + phone"
                   target="_blank"
                   class="d-inline-flex me-auto pb-0 mb-0 link">
                  {{ this.formatPhone(phone, true) }}
                </a>
              </li>
            </ul>
          </li>
          <li v-if="!this.isEmpty(this.item.email)"
              class="d-flex flex-column mb-3">
            <span class="mb-1 fs-5 text-font-secondary">Почта</span>
            <a :href="'mailto:'+this.item.email" target="_blank"
               class="d-inline-flex me-auto pb-0 mb-2 link">{{ this.item.email }}</a>
          </li>
          <li v-if="!this.isEmpty(this.item.objects)"
              class="d-flex flex-column mb-3">
                        <span v-if="Object.entries(this.item.objects).length > 1"
                              class="mb-1 fs-5 text-font-secondary">Объекты</span>
            <span v-else
                  class="mb-1 fs-5 text-font-secondary">Объект</span>
            <ul class="d-flex flex-wrap px-0" style="max-width: 80%;">
              <li v-for="object in this.item.objects" :key="object.id"
                  class="me-20">
                <a href="javascript:{}"
                   :data-bs-object-id="object.id"
                   @click="this.alertNote('Данный функционал в стадии разработки.')"
                   class="d-inline-flex me-auto pb-0 mb-0 link">
                  {{ object.title }}
                </a>
              </li>
            </ul>
          </li>
          <li v-if="!this.isEmpty(this.item.budget)"
              class="d-flex flex-column mb-3">
            <span class="mb-1 fs-5 text-font-secondary">Бюджет</span>
            <span class="d-inline-flex me-auto pb-0 mb-2">
							{{ this.formatPrice(this.item.budget, 'RUR', true) }}
						</span>
          </li>
          <li v-if="!this.isEmpty(this.item.deal_price)"
              class="d-flex flex-column mb-3">
            <span class="mb-1 fs-5 text-font-secondary">Сумма сделки</span>
            <span class="d-inline-flex me-auto pb-0 mb-2">
							{{ this.formatPrice(this.item.deal_price, 'RUR', true) }}
						</span>
          </li>
          <li v-if="!this.isEmpty(this.item.contracts) && !this.isEmpty(this.contract_types)"
              class="d-flex flex-column mb-3">
            <span class="mb-1 fs-5 text-font-secondary">Договоры</span>
            <ul v-if="!this.isEmpty(this.item.contracts)"
                class="list-unstyled d-flex flex-column">
              <li v-for="contract in this.item.contracts" :key="contract.id"
                  class="d-inline-block mb-2">
                <a href="javascript:{}"
                   class="link fs-4"
                   data-bs-toggle="modal"
                   data-bs-target="#addEditContractModal"
                   :data-bs-contract-id="contract.id"
                   :data-bs-source-id="item.id"
                   :data-bs-section="'clients'">
                  <img :src="require('@/assets/icons/pen-icon.svg')" alt="Edit" class="d-inline-flex ms-0 me-1" width="14" height="14" style="margin-top: 1px;">
                  {{ this.getContractType(contract.type) }}
                  "{{ contract.number }}"
                  с {{ this.formatDate('DD.MM.YYYY', contract.date_start) }}
                  по {{ this.formatDate('DD.MM.YYYY', contract.date_end) }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <button type="button"
              class="btn btn-history-tasks bg-light-gray text-secondary border border-brd-primary rounded-1 font-semi fw-semi position-relative"
              data-bs-toggle="modal"
              data-bs-section="clients"
              :data-bs-client-id="this.item.id"
              data-bs-target="#historyTasksModal">
        История и задачи
        <span class="badges-group">
					<span v-if="!this.isEmpty(this.item.events_count.clients.expired)"
                class="badge bg-danger"
                style="width: 26px; height: 26px;">
						{{ this.item.events_count.clients.expired }}
						<span class="visually-hidden">Просроченные задачи</span>
					</span>
					<span v-if="!this.isEmpty(this.item.events_count.clients.today)"
                class="badge bg-warning"
                style="width: 26px; height: 26px;">
						{{ this.item.events_count.clients.today }}
						<span class="visually-hidden">Задачи на сегодня</span>
					</span>
					<span v-if="!this.isEmpty(this.item.events_count.clients.total)"
                class="badge bg-success"
                style="width: 26px; height: 26px;">
						{{ this.item.events_count.clients.total }}
						<span class="visually-hidden">Непрочитанные задачи</span>
					</span>
				</span>
      </button>
      <router-link :to="'/edit-client?id=' + this.item.id + '#requisitions'"
                   v-if="this.item.this_user_can_edit_clients"
                   class="btn bg-light-gray text-secondary border border-brd-primary rounded-1 ms-3 font-semi fw-semi position-relative">
        Заявки
        <span class="badges-group">
					<span v-if="!this.isEmpty(this.item.events_count.requisitions)"
                class="badge bg-danger"
                style="width: 26px; height: 26px;">
						{{ this.item.events_count.requisitions }}
                    <span class="visually-hidden">Новые заявки</span>
					</span>
				</span>
      </router-link>
    </div>

    <!--footer-->
    <div class="">
      <div class="d-flex flex-column py-20">

				<span v-if="!this.isEmpty(this.item.who_added)"
              class="text-font-light fs-5">
					Добавил
					<span v-text="this.item.who_added.name"/>
					<span v-if="!this.isEmpty(this.item.who_added.datetime)"
                v-text="' — ' + this.formatDateTime(this.item.who_added.datetime, 'L')"/>
				</span>

        <span v-if="this.item.is_confirm == 0 && this.item.is_deleted == 0 && !this.isEmpty(this.item.who_work)"
              class="text-font-light fs-5">
					Должен принять в работу
					<span v-text="this.item.who_work.name"/>
					<span v-if="!this.isEmpty(this.item.who_work.datetime)"
                v-text="' c ' + this.formatDateTime(this.item.who_work.datetime, 'L')"/>
				</span>
        <span
            v-else-if="this.item.is_confirm > 0 && this.item.is_deleted > 0 && !this.isEmpty(this.item.who_work)"
            class="text-font-light fs-5">
					Был в работе у
					<span v-text="this.item.who_work.name"/>
					<span v-if="!this.isEmpty(this.item.who_work.datetime)"
                v-text="' до ' + this.formatDateTime(this.item.who_work.datetime, 'L')"/>
				</span>
        <span v-else-if="!this.isEmpty(this.item.who_work)"
              class="text-font-light fs-5">
					В работе у
					<span v-text="this.item.who_work.name"/>
					<span v-if="!this.isEmpty(this.item.who_work.datetime)"
                v-text="' c ' + this.formatDateTime(this.item.who_work.datetime, 'L')"/>
				</span>

        <span v-if="this.item.is_confirm > 0 && this.item.is_deleted > 0 && !this.isEmpty(this.item.who_delete)"
              class="text-font-light fs-5">
					Закрыл
					<span v-text="this.item.who_delete.name"/>
					<span v-if="!this.isEmpty(this.item.who_delete.datetime)"
                v-text="' — ' + this.formatDateTime(this.item.who_delete.datetime, 'L')"/>
				</span>

        <span v-if="!this.isEmpty(this.item.doers_requests)"
              class="text-font-light fs-5">
					Совместная работа c
					<span v-for="(user, index) in this.item.doers_requests" :key="index"
                v-text="(index > 0) ? ', ' + user.name : user.name"/>
				</span>

        <span v-if="!this.isEmpty(this.item.doers_accepted)"
              class="text-font-light fs-5">
					Приглашен к совместной работе c
					<span v-for="(user, index) in this.item.doers_accepted" :key="index"
                v-text="(index > 0) ? ', ' + user.name : user.name"/>
				</span>
      </div>
      <div v-if="!this.isEmpty(this.item.description) && this.isClientContentOpened"
           class="d-flex flex-column pt-20 border-top border-brd-primary pb-20">
        <span class="text-font-light mb-10">Описание</span>
        <span class="fs-4" v-html="this.item.description"/>
      </div>
      <div v-if="!this.item.is_confirm && (this.item.employee_id != this.$store.getters.userInfo.id || this.isEmpty(this.item.employee_id))" class="d-flex flex-column pt-3 border-top border-light">
        <span class="d-inline-flex mb-3 align-self-center">Взять клиента в работу?</span>
        <ul class="nav justify-content-center mb-3">
          <li class="nav-item px-2">
            <button class="nav-item btn btn-warning rounded-3 px-4"
                    @click="this.fillConfirmModalState('cancelTranfer')">Нет</button>
          </li>
          <li class="nav-item px-2">
            <button class="nav-item btn btn-success text-white rounded-3 px-4"
                    @click="this.acceptTransfer()">Да</button>
          </li>
        </ul>
      </div>
      <div v-else-if="!this.item.is_confirm && !this.isEmpty(this.item.employee_id)" class="d-flex flex-column pt-3 border-top border-light">
        <span class="d-inline-flex mb-3 align-self-center">Вы передали этого клиента.</span>
        <ul class="nav justify-content-center mb-3">
          <li class="nav-item px-2">
            <button class="nav-item btn btn-danger text-white rounded-3 px-4"
                    @click="this.fillConfirmModalState('cancelTranfer')">Отменить передачу</button>
          </li>
        </ul>
      </div>
    </div>

    <!--Модалки-->

    <ConfirmModal :id="this.item.id"
                  :title="this.confirmModalState.title"
                  :text="this.confirmModalState.text"
                  :recipient="this.confirmModalState.recipient"
                  :fromSection="this.confirmModalState.fromSection"
                  :action="this.confirmModalState.action"
                  :btnText="this.confirmModalState.btnText"
                  @confirmed="(data) => {
						  if (data.action == 'restoreClient') {
						  	this.initRestoreClient(data.id);
						  } else if (data.action == 'cancelTransfer') {
						  	this.cancelTransfer(data.id);
						  }
					  }" />

  </div>
</template>

<script>
import api from "@/api";
import CommonService from "@/services/CommonService";
import ConfirmModal from "@/components/modals/ConfirmModal";
import {copyText} from "vue3-clipboard";

export default {
  name: 'ClientCard',
  components: {
    ConfirmModal
  },
  props: {
    clientItem: { type: Object },
    isChecked: { type: Boolean },
    tagsList: { type: Array, default: () => [] },
    sourcesList: { type: Array, default: () => [] },
    partnersList: { type: Array, default: () => [] },
    contractTypes: { type: Array, default: () => [] },
  },
  data() {
    return {
      isClientContentOpened: false,
      confirmModalState: {
        title: '',
        text: '',
        recipient: '',
        fromSection: 'clientCard',
        action: null,
        btnText: ''
      }
    }
  },
  methods: {
    copyID(client_id) {
      copyText(client_id, undefined, (error, event) => {
        if (error) {
          console.error('Не удалось скопировать ID клиента: ', error);
          alert('Не удалось скопировать ID клиента.');
        } else {
          alert('ID клиента скопирован!');
          console.log(event);
        }
      });
    },
    formatPhone(value) {
      return CommonService.formatPhoneNumber(value);
    },
    formatDateTime(datetime, format) {
      if (typeof format !== 'undefined')
        return CommonService.formatDateTime(datetime, 'ru', format);
      else
        return CommonService.formatDateTime(datetime, 'ru');
    },
    alertNote(message) {
      return alert(message);
    },
    isEmpty(data) {
      return CommonService.isEmpty(data);
    },
    inArray(needle, haystack) {
      return CommonService.inArray(needle, haystack);
    },
    formatDate(format, datetime) {
      return CommonService.formatDateTime(datetime, 'ru', format);
    },
    formatPrice(value, currency, sign) {
      return CommonService.formatPrice(value, currency, sign);
    },
    sortArray(data) {
      return CommonService.sortArray(data);
    },
    getContractType(type_id) {

      let contract_type = false;
      if (typeof type_id !== "undefined" && !this.isEmpty(this.contract_types)) {
        this.contract_types.forEach((type) => {
          if (type.value == type_id)
            contract_type = type.name;
        });
      }

      return contract_type;
    },
    fillConfirmModalState(str) {
      if (str === 'restoreClient') {
        this.confirmModalState.title = "Восстановление клиента";
        this.confirmModalState.text = "Вы действительно хотите восстановить клиента?";
        this.confirmModalState.action = 'restoreClient';
        this.confirmModalState.btnText = "Подтвердить";
      } else if (str === 'cancelTransfer') {
        this.confirmModalState.title = "Отмена передачи";
        this.confirmModalState.text = "Вы действительно хотите отменить передачу клиента?";
        this.confirmModalState.action = 'cancelTransfer';
        this.confirmModalState.btnText = "Подтвердить";
      }
    },
    acceptTransfer() {
      api.put('/clients/transfer', {
        clients_ids: [this.item.id]
      }).then((response) => {

        CommonService.log('debug', 'acceptTransfer()::axios', {response});

        this.$emit('updateClients');
      }).catch(function (error) {

        CommonService.log('error', 'acceptTransfer()::axios', error);

      });
    },
    deleteClient() {
      if (confirm('Вы точно хотите безвозвратно удалить этого клиента?')) {
        api.delete('/client', {
          params: {
            client_id: this.item.id
          }
        }).then((response) => {

          CommonService.log('debug', 'deleteClient()::axios', {response});

          this.$emit('updateClients');
        }).catch(function (error) {

          CommonService.log('error', 'deleteClient()::axios', error);

        });
      }
    },
    cancelTransfer(client_id) {
      api.put('/clients/transfer', {
        clients_ids: [client_id],
        cancel_transfer: true
      }).then((response) => {

        CommonService.log('debug', 'initTransfer()::axios', {response});

        this.$emit('updateClients');
      }).catch(function (error) {

        CommonService.log('error', 'initTransfer()::axios', error);

      });
    },
    initRestoreClient(client_id) {
      if (client_id) {
        let clients_ids = [];
        clients_ids.push(client_id);
        api.post('/clients/restore', {
          clients_ids: clients_ids
        }).then((response) => {
          CommonService.log('debug', 'initRestoreClient()::axios', {response});

          if (response.status == 200 && response.data.success)
            this.$emit('initRestoreClient', {clients_ids: clients_ids});

        }).catch(function (error) {

          CommonService.log('error', 'initRestoreClient()::axios', error);

        });
      }
    },
    buildTagsList(tags, tags_list) {
      let list = CommonService.resetProxy(tags_list);
      if (tags && list) {
        list.find((item, i) => {
          if (tags.indexOf(item.id) !== -1) {
            list[i].isChecked = true;
          } else {
            list[i].isChecked = false;
          }
        });
      }

      return list;
    },
    showClientContent() {

      if (this.item.is_new) {
        api.put('/common/viewed', {
          client_id: this.item.id
        }).then((response) => {

          CommonService.log('debug', 'showClientContent()::axios', {response: response.data});

          if (response.status == 200 && response.data.success) {
            this.item.is_new = false;
          }

        }).catch(function (error) {

          CommonService.log('error', 'showClientContent()::axios', error);

        });
      }

      this.isClientContentOpened = !this.isClientContentOpened;
    },
  },
  computed: {
    item() {
      return this.$props.clientItem ?? {};
    },
    is_checked: {
      get() {
        return this.$props.isChecked ?? this.value;
      },
      set(value) {
        this.$emit("setItemChecked", {item_id: this.item.id, checked: value});
      },
    },
    source() {

      let sources = this.$props.sourcesList ?? {};
      if (!this.isEmpty(sources)) {

        let source = sources.filter((source) => source.value == this.item.source_id).map((source) => {
          return source.name;
        });

        if (!this.isEmpty(source))
          return source.toString();

      }

      return null;
    },
    partner() {

      let partners = this.$props.partnersList ?? {};
      if (!this.isEmpty(partners)) {

        let partner = partners.filter((partner) => partner.value == this.item.partner_id).map((partner) => {
          return partner.company;
        });

        if (!this.isEmpty(partner))
          return partner.toString();

      }

      return null;
    },
    contract_types() {
      return this.$props.contractTypes ?? {};
    },
    tags() {
      let tags = null;
      let selected = this.item.tags;

      if (selected)
        tags = this.buildTagsList(selected, this.$props.tagsList);
      else
        tags = this.$props.tagsList;

      return tags;
    },
    tags_filtered() {
      return this.tags.filter(tag => tag.isChecked);
    },
    user_can() {
      return {
        delete: this.$store.getters.userInfo.user_can.delete_clients,
      };
    },

    is_last_stage() {
      if (!this.item.funnel_id || this.item.funnel_id == 0) {
        return Boolean(this.item.stage_id == 8);
      }

      return false;
    },
  }
}
</script>