<template>
	<div class="container">
		<div class="row	">
			<div class="col col-sm-10 col-md-8 col-lg-6 col-xl-4 col-xxl-4 mx-auto py-3 pt-4 px-5 pb-10 mx-4 bg-white border-bottom border-brd-primary mb-4 rounded-1 shadow position-relative">
				<div class="page-header mb-4">
					<h1 class="page-title" v-text="this.title" />
				</div>
				<LoginForm />
			</div>
		</div>
	</div>
</template>

<script>
    import CommonService from "../services/CommonService";
	import LoginForm from "@/components/forms/LoginForm";

    export default {
        name: "Restore",
		components: {
			LoginForm
		},
		props: {

		},
		data() {
            return {
                title: "Восстановление доступа"
            };
        },
        methods: {

        },
        mounted() {
            CommonService.setPageTitle(this.title);
			this.$store.commit('setUserInfo', {});
        }
    };
</script>