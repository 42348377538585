<template>
	<div class="clients position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative"
			 style="z-index:1000;">
			<div class="page-header">
				<h1 class="page-title">Клиенты</h1>
				<div class="d-flex border border-brd-primary border-start-0 border-end-0 border-bottom-1 mb-2 pt-3 pb-3">
					<div class="input-group">
						<button id="button-addon1"
								type="button"
								class="btn btn-outline-primary me-2 rounded-3 fw-semi font-semi"
								@click="this.isFilterOpened = !this.isFilterOpened">
							{{ this.filtersCountLabel }}
							<img :src="require('@/assets/icons/arr-sm-down-icon.svg')" class="ms-1 icon-color-primary" alt="Развернуть" width="12" height="12">
						</button>
						<input type="text" class="form-control border-0 px-1 me-2"
							   placeholder="ID, имя или контакты клиента"
							   aria-label="Поиск по имени или контактной информации клиента"
							   ref="searchInput"
							   @change="(event) => this.search_query = event.target.value.toString()" />
						<button v-if="this.show_reset_filter" id="button-addon3" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-2 me-0 border border-brd-primary rounded-1"
								aria-label="Очистить"
								@click="this.resetFilter()">
							<img :src="require('@/assets/icons/close-icon.svg')" alt="Очистить" width="14" height="14">
						</button>
						<button id="button-addon2" type="button" class="crm-btn btn btn-outline-secondary bg-light-gray me-0 border border-brd-primary rounded-1"
								aria-label="Осуществить поиск"
								@click="this.doSearch()">
							<img :src="require('@/assets/icons/search-icon.svg')" alt="Поиск" width="18" height="18">
						</button>
					</div>
				</div>
                <router-link :to="'/add-client?funnel_id='+this.filters.funnel_id"
							 class="btn d-flex align-items-center p-0 rounded-pill">
                    <span class="d-flex align-items-center justify-content-center bg-primary rounded-circle me-1"
						  style="width: 40px; height: 40px;">
                        <img :src="require('@/assets/icons/add-icon.svg')" class="d-block" alt="Добавить" width="14" height="14">
                    </span>
                    <span class="text-font-dark fw-semi font-semi fs-4 px-2">Новый клиент</span>
                </router-link>
			</div>

			<Filters :isOpen="this.isFilterOpened"
					 section="clients"
					 :filters="this.filters"
					 :options="this.filterOptions"
					 @closeFilter="data => this.closeFilter(data)"
					 @setupFilter="this.filterChange"
					 @applyFilter="this.applyFilter" />

        </div>

        <div class="container px-20"
			 ref="clientsContainer">

            <div class="pt-20 mb-20">
                <InputSelect inputLabelClass="text-font-secondary mb-3"
							 inputId="client_funnels"
							 inputName="client_funnels"
							 :inputOptions="this.funnels"
							 :inputValue="this.filters.funnel_id"
							 :inputUsePlaceholder="false"
							 :inputDisabled="!this.isEmpty(this.search_query)"
							 inputClass="rounded-1 p-20 py-10"
							 @setValue="(value) => {
								this.filters.funnel_id = value;
								this.getClients();
							 }" />
            </div>

            <div class="d-flex align-items-center mb-20">
                <div class="form-check me-auto">
                    <input class="form-check-input rounded-1"
						   type="checkbox"
						   value=""
						   id="selectAll"
						   ref="selectAll"
						   @click="this.setAllChecked" />
                    <label class="form-check-label pe-10" for="selectAll">
                        Выделить всех клиентов
                    </label>
                </div>
                <ActionsListDropdown :isDisabled="this.selected.length == 0"
									 :actionsList="this.getActionOptions()" />
            </div>
            <div class="mb-20 d-flex align-items-center">
                <span class="clients__total me-auto text-font-secondary">
					Всего: <span class="text-font-primary" v-text="(this.pagination.total) ? this.pagination.total : 0" />
				</span>
                <div v-show="this.pagination.total > 0" class="d-flex align-items-center">
                    <span class="d-none d-sm-block me-3 text-font-secondary">Сортировка:</span>
                    <InputSelect inputId="sort_order"
								 inputName="sort_order"
								 :inputOptions="this.sort_order_list"
								 :inputValue="this.sort_order"
								 inputPlaceholder="По умолчанию"
								 inputClass="rounded-1 p-20 py-10"
								 inputLabelClass="text-font-secondary mb-3"
								 @setValue="(value) => {
									this.sort_order = value;
									this.getClients();
								}" />
                </div>
            </div>
        </div>

		<div v-if="this.is_loading_process" class="container px-20 d-flex flex-column align-items-center">
			<Loader />
		</div>
		<div v-else-if="!this.isEmpty(this.items)" class="container px-20">
			<ul class="p-0 list-unstyled">
				<li v-for="item in this.items"
					:key="item.id"
					class="mb-20">
					<ClientCard :clientItem="item"
								:tagsList="this.tags_list"
								:sourcesList="this.sources_list"
								:partnersList="this.partners_list"
								:isChecked="this.isAllChecked"
								:contractTypes="this.contract_types"
								@setItemChecked="this.setItemChecked"
								@initRestoreClient="this.getClients()"
								@updateClients="this.goToPage()" />
				</li>
			</ul>
		</div>
		<div v-else class="col px-20 d-flex flex-column align-items-center">
			<EmptyList message="Клиенты не найдены" />
		</div>

		<Pagination v-if="!this.isEmpty(this.items) && (this.pagination.total > this.pagination.per_page)"
					:initialPage="this.pagination.current"
					:itemsList="this.pagination.items"
					:labels="{
						first: false,
						last: false,
						previous: false,
						next: false
					}"
					:pageSize="this.pagination.per_page"
					:disableDefaultStyles="true"
					:maxPages="3"
					@changePage="(page) => this.goToPage(page)" />

    </div>

	<SetTagsModal @saveSuccess="(tags_ids, source_id) => this.setTags(tags_ids, source_id)" />

	<DelegateClientModal :clientsIds="this.selected"
						 @initTransfer="(data) => this.resetAllChecked('delegate_client', data)" />

	<CloseClientModal :clientsIds="this.selected"
					  @initClose="(data) => this.resetAllChecked('close_client', data)" />

	<ChangeFunnelModal :sourcesIds="this.selected"
					   section="clients"
					   @initChangeFunnel="(data) => this.resetAllChecked('change_funnel', data)" />

	<HistoryTasksModal fromSection="clients" />

	<ConfirmModal :id="this.confirmModalState.id"
				  :title="this.confirmModalState.title"
				  :text="this.confirmModalState.text"
				  :recipient="this.confirmModalState.recipient"
				  :fromSection="this.confirmModalState.fromSection"
				  :action="this.confirmModalState.action"
				  :btnText="this.confirmModalState.btnText"
				  @confirmed="(data) => {
						  if (data.action == 'restoreClients') {
						  	this.initRestoreClients();
						  }
					  }" />


	<AddEditContractModal @contractAdded="(data) => this.setContract(data.source_id, data.contract)" />

	<ChangeStageModal fromSection="funnels"
					  @setPrevStage="this.getClients(this.page, true)"
					  @setStageClosed="this.getClients(this.page, true)"
					  @setStageAndClientClosed="this.filters['status'] = 'stage_close' && this.getClients(this.page)" />

	<SubmitToVKModal />
	<SubmitComplaintModal />
	<PresentationNav />


</template>

<script>
	import api from "@/api";
	import Loader from "@/components/common/Loader";
	import CommonService from "../services/CommonService";
	import ClientCard from "@/components/clients/ClientCard"
	import ActionsListDropdown from "@/components/common/ActionsListDropdown"
	import InputSelect from '@/components/inputs/InputSelect'
	import Filters from "@/components/common/Filters"
	import Pagination from "@/components/common/Pagination";
	import SetTagsModal from "@/components/modals/SetTagsModal";
	import DelegateClientModal from "@/components/modals/DelegateClientModal";
	import ChangeFunnelModal from "@/components/modals/ChangeFunnelModal";
	import CloseClientModal from "@/components/modals/CloseClientModal";
	import ConfirmModal from "@/components/modals/ConfirmModal";
	import AddEditContractModal from "@/components/modals/AddEditContractModal";
	import HistoryTasksModal from "@/components/modals/HistoryTasksModal";
	import EmptyList from "@/components/common/EmptyList";
	import PresentationNav from "@/components/common/PresentationNav";
	import SubmitComplaintModal from "@/components/modals/SubmitComplaintModal";
	import SubmitToVKModal from "@/components/modals/SubmitToVKModal";
	import ChangeStageModal from "@/components/modals/ChangeStageModal";
	import {CommonDataService} from "@/services/CommonDataService";

    export default {
        name: "clients",
        components: {
			ChangeStageModal,
			SubmitToVKModal,
			SubmitComplaintModal,
			PresentationNav,
			EmptyList,
			HistoryTasksModal,
			AddEditContractModal,
			ConfirmModal,
			CloseClientModal,
			ChangeFunnelModal,
			DelegateClientModal,
			SetTagsModal,
			Loader,
			Pagination,
            ClientCard,
			Filters,
			ActionsListDropdown,
            InputSelect
        },
		props: {
			funnelId: {type: Number, default: 0 }
		},
        data() {
            return {
                title: "Клиенты",
				filtersCount: 0,
				filtersCountLabel: 'Фильтр',
				isFilterOpened: false,
                isAllChecked: false,
				is_loading_process: true,
				items: [],
                filters: {
					funnel_id: 0,
					status: 'stage_work',
				},
				filterOptions: {
					status: {
						'stage_work': {
							label: 'В работе',
							count: 0,
							title: 'В работе',
							icon: null,
						},
						'stage_hot': {
							label: 'Горячие',
							count: 0,
							title: 'Горячие',
							icon: require('@/assets/icons/fire-icon.svg'),
						},
						'stage_close': {
							label: 'Закрытые',
							count: 0,
							title: 'Закрытые',
							icon: null,
						},
						'stage_hit': {
							label: 'Успешные',
							count: 0,
							title: 'Успешные',
							icon: null,
						},
						'confirmed_clients': {
							label: 'Переданные',
							count: 0,
							title: 'Переданные',
							icon: null,
						},
						'common_clients': {
							label: 'Общие',
							count: 0,
							title: 'Общие',
							icon: null,
						}
					}
				},
				actionOptions: {
					'delegate_client': {
						href: null,
						modal: '#delegateClientModal',
						label: 'Передать сотруднику',
						title: 'Передать сотруднику',
						icon: require('@/assets/icons/delegation-icon.svg'),
					},
					'change_funnel': {
						href: null,
						modal: '#changeFunnelModal',
						label: 'Переместить в воронку',
						title: 'Переместить в воронку',
						icon: require('@/assets/icons/stage-move-icon.svg'),
					},
					'close_client': {
						href: null,
						modal: '#closeClientModal',
						label: 'Закрыть клиента',
						title: 'Закрыть клиента',
						icon: require('@/assets/icons/basket-icon.svg'),
					},
				},
				sort_order: null,
				search_query: '',
				sort_order_list: [
					{
						name: 'От старого к новому',
						value: 'asc',
					}, {
						name: 'От нового к старому',
						value: 'desc',
					},
				],
				page: 1,
				per_page: 10,
				funnels: [],
				selected: [],
				pagination: {
					page: 0,
					pages: 0,
					count: 0,
					total: 0,
					per_page: 10,
				},
				tags_list: [],
				sources_list: [],
				partners_list: [],
				contract_types: [],
				confirmModalState: {
					id: null,
					title: '',
					text: '',
					recipient: '',
					fromSection: 'clientsList',
					action: null,
					btnText: ''
				},
				is_rollback: false,
            };
        },

        methods: CommonService.mergeRecursive({
            setAllChecked() {
				this.isAllChecked = !this.isAllChecked;

				if (this.isAllChecked) {
					this.selected = [];
					this.items.forEach((item, index) => {
						this.selected.push(item.id);
					});
				} else {
					this.selected = [];
				}

				CommonService.log('debug', 'setAllChecked()', this.selected);

            },
			resetAllChecked(action, data) {
				this.isAllChecked = false;
				this.selected = [];

				CommonService.log('debug', 'resetAllChecked()', {action: action, data: data});

				this.getClients();
            },
			setItemChecked(item) {
				let index = this.selected.indexOf(item.item_id);
				if (index !== -1) {
					this.selected.splice(index, 1);
				} else {
					this.selected.push(item.item_id);
				}

				CommonService.log('debug', 'setItemChecked()', this.selected);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			fillConfirmModalState(str) {
				if(str === 'restoreClients') {
					this.confirmModalState.id = "RestoreSelected";
					this.confirmModalState.title = "Восстановление клиентов";

					if (this.selected.length > 1)
						this.confirmModalState.text = "Вы действительно хотите восстановить выбранных клиентов?";
					else
						this.confirmModalState.text = "Вы действительно хотите восстановить выбранного клиента?";

					this.confirmModalState.action = 'restoreClients';
					this.confirmModalState.btnText = "Подтвердить";
				}
			},
			getActionOptions() {
				if (this.filters.status == 'stage_close' || this.filters.status == 'stage_hit') {
					delete this.actionOptions['close_client'];
					this.actionOptions['restore_client'] = {
						href: null,
						modal: '#clientsListRestoreSelected',
						label: 'Восстановить клиента',
						title: 'Восстановить клиента',
						onclick: this.fillConfirmModalState('restoreClients'),
						icon: require('@/assets/icons/back-icon.svg'),
					};
				} else {
					delete this.actionOptions['restore_client'];
					this.actionOptions['close_client'] = {
						href: null,
						modal: '#closeClientModal',
						label: 'Закрыть клиента',
						title: 'Закрыть клиента',
						icon: require('@/assets/icons/basket-icon.svg'),
					};
				}

				return this.actionOptions;
			},
			scrollToContainer() {
				const headerHeight = 100;
				const position = this.$refs.clientsContainer.getBoundingClientRect().top;
				const offset = position + window.pageYOffset - headerHeight;
				this.$nextTick(() => {
					window.scrollTo({
						top: offset,
						behavior: "smooth"
					});
				});
			},
			setContract: function (client_id, contract) {
				if (typeof contract !== "undefined") {

					let _this = this;
					[].forEach.call(_this.items, function (item, key) {

						if (parseInt(item.id) == parseInt(client_id) && parseInt(client_id) !== 0) {

							let index = _this.items[key].contracts.findIndex(i => i.id == contract.id);
							if (index !== -1)
								_this.items[key].contracts[index] = contract;
							else
								_this.items[key].contracts.push(contract);
						}
					});
				}

				CommonService.log('debug', 'setContract', {contract: contract, client_id: client_id});

			},
			setTags: function (tags, client_id) {
				if (typeof tags !== "undefined") {

					let _this = this;
					[].forEach.call(_this.items, function (item, key) {
						if (parseInt(item.id) == parseInt(client_id) && parseInt(client_id) !== 0) {
							_this.items[key].tags = tags;
						}
					});
				}

				CommonService.log('debug', 'setTags', {tags: tags, client_id: client_id});

			},
			doSearch() {
				let query = this.search_query;
				if (!CommonService.isEmpty(query)) {

					CommonService.log('log', 'doSearch()', query);

					this.getClients();
				}
			},
			getFiltersCount() {
				let count = 0;
				for (const filter_name of Object.keys(this.filters)) {
					if (filter_name !== 'funnel_id' && filter_name !== 'sort_order')
						count++;
				}
				console.log('getFiltersCount()', this.filters);
				return count;
			},
			/*filterChange(filter) {
				if (filter.name == 'status') {
					this.isFilterOpened = false;
					this.goToPage();
				}
			},*/
			/*setupFilter(filters) {

				//filters = CommonService.removeEmpty(filters);
				this.filters = filters;

				CommonService.log('log', 'setupFilter()', this.filters);

				this.filtersCount = this.getFiltersCount();
			},*/
			/*applyFilter(filters) {

				filters = CommonService.removeEmpty(filters);
				this.filters = filters;

				CommonService.log('log', 'applyFilter()', this.filters);

				this.filtersCount = this.getFiltersCount();

				if (this.filters.status['stage_close'] && "close_client" in this.actionOptions) {
					delete this.actionOptions['close_client'];
					this.actionOptions['restore_client'] = {
						href: null,
						modal: '#restoreClientModal',
						label: 'Восстановить клиента(ов)',
						title: 'Восстановить клиента(ов)',
						icon: require('@/assets/icons/back-icon.svg'),
					};
				} else if (!this.filters.status['stage_close'] && "restore_client" in this.actionOptions) {
					delete this.actionOptions['restore_client'];
					this.actionOptions['close_client'] = {
						href: null,
						modal: '#closeClientModal',
						label: 'Закрыть клиента(ов)',
						title: 'Закрыть клиента(ов)',
						icon: require('@/assets/icons/basket-icon.svg'),
					};
				}

				this.getClients();
			},*/
			filterChange(filters) {

				filters = CommonService.removeEmpty(filters);
				this.filters = filters;

				CommonService.log('log', 'filterChange()', this.filters);

				this.filtersCount = this.getFiltersCount();
			},
			applyFilter(filters) {

				this.isFilterOpened = false;

				filters = CommonService.removeEmpty(filters);

				CommonService.log('log', 'applyFilter()', this.filters);

				this.getClients();
			},
			getFunnels() {
				let _this = this;
				return api.get('common/funnels', {
					params: {
						section: 'clients',
						use_counters: true,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFunnels()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {

							let count = '';
							if (option.count)
								count = ' ('+option.count+')';

							list.push({
								value: option.id,
								name: option.name + count
							});

							if (option.is_default && !_this.is_rollback) {
								if (typeof _this.filters.funnel_id !== "undefined") {
									_this.filters.funnel_id = option.id;
								}
							}

						});

						if (typeof _this.$store !== "undefined") {
							_this.$store.commit('setFunnelsList', {
								section: 'clients',
								list: list
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getFunnels()::axios', error);

				});
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
				.then((response) => {

					CommonService.log('debug', 'getSources()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, source]) => {
								list.push({
									value: source.id.toString(),
									name: source.name
								});
							});
						}

						CommonService.log('info', 'Clients::getSources()', list);

						if (typeof _this.$store !== "undefined")
							_this.$store.commit('setSourcesList', list);

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getSources()::axios', error);

				});
			},
			getTypes(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, option]) => {
								list.push({
									value: option.id,
									name: option.name
								});
							});

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
				}
			},
			getPartners() {
				return api.get('/partners/list', {
					params: {
						filters: {
							only_active: 1,
							with_managers: 1
						}
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartners()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							let groups = (response.data.groups) ? response.data.groups : [];
							Object.entries(response.data.list).forEach(([key, partner]) => {
								let group_id = partner.group_id ?? null;
								list.push({
									value: partner.id.toString(),
									name: partner.name,
									company: (!this.isEmpty(groups[group_id])) ? groups[group_id] : null,
								});
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getPartners()::axios', error);

				});
			},
			initRestoreClients() {
				if (this.selected.length > 0) {
					api.post('/clients/restore', {
						clients_ids: this.selected
					}).then((response) => {
						CommonService.log('debug', 'initRestoreClients()::axios', {response});

						if (response.status == 200 && response.data.success)
							this.getClients();

					}).catch(function (error) {

						CommonService.log('error', 'initRestoreClients()::axios', error);

					});
				}
			},
			getClients(page, lazy_load) {

				if (!lazy_load) {
					this.selected = [];
					this.items = [];
					this.pagination = {};
					this.$refs.selectAll.checked = false;
					this.isAllChecked = false;
				}

				if (typeof lazy_load == "undefined")
					lazy_load = false;

				if (typeof page == "undefined" && this.isEmpty(this.pagination.current))
					page = 1;

				if (!lazy_load)
					this.is_loading_process = true;

				return api.get('/clients', {
					params: {
						page: page ?? this.pagination.current,
						per_page: this.pagination.per_page ?? 10,
						sort_order: this.sort_order,
						filters: this.filters,
						query: this.search_query,
					},
					//xdebug: true
				})
				.then((response) => {

					CommonService.log('debug', 'getClients()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						if (!lazy_load)
							this.is_loading_process = false;

						let list = [];
						if (!this.isEmpty(response.data.clients)) {
							list = response.data.clients;
						}

						if (!this.isEmpty(response.data.pagination)) {
							this.pagination = response.data.pagination;
							this.pagination.items = [...Array(this.pagination.pages * this.pagination.per_page).keys()].map(i => ({
								id: (i + 1),
								name: 'Item ' + (i + 1)
							}));
						}

						if (!this.isEmpty(response.data.count))
							this.pagination.count = parseInt(response.data.count);

						if (!this.isEmpty(response.data.total))
							this.pagination.total = parseInt(response.data.total);

						if (!this.isEmpty(response.data.page))
							this.pagination.current = parseInt(response.data.page);

						if (!this.isEmpty(response.data.tags_list)) {
							let tags_list = [];
							Object.entries(response.data.tags_list).forEach(([key, tag]) => {
								tag.isChecked = false;
								tags_list.push(tag);
							});
							this.tags_list = tags_list;
						}

						this.items = list;

						if (this.isFilterOpened)
							this.isFilterOpened = false;

						/*if (!lazy_load)
							this.scrollToContainer();*/

						//this.$store.commit('setObjectClientsList', this.items);
						return this.items;
					}

					if (!lazy_load)
						this.is_loading_process = false;

					return {};
				}).catch(function (error) {

					CommonService.log('error', 'getClients()::axios', error);

					if (!lazy_load)
						this.is_loading_process = false;

				});
			},
			goToPage(page) {
				let items = this.getClients(page);

				CommonService.log('debug', 'goToPage()', {page, items});

				return items;
			},
			resetFilter() {
				this.$refs.searchInput.value = null;
				this.search_query = null;

				if (Object.keys(this.filters).length > 0) {
					this.filters = {
						funnel_id: 0,
						//status: 'stage_work',
						status: null,
					};

					//this.filtersCount = 1;
					this.filtersCount = 0;

					CommonService.log('log', 'resetFilter()', this.filters);

				}

				this.getClients();
			},
			closeFilter(clear) {
				this.isFilterOpened = false;

				if (clear) {
					this.resetFilter();
				}
			},
        }, CommonDataService.methods),
        mounted() {
            CommonService.setPageTitle(this.title);

			if (typeof this.$props.funnelId !== "undefined") {
				this.filters.funnel_id = Number(this.$props.funnelId);
				this.is_rollback = true;
			} else {
				this.is_rollback = false;
			}

			this.filtersCount = this.getFiltersCount();

			if (this.filtersCount > 0)
				this.filtersCountLabel = CommonService.declOfNum(this.filtersCount, ['опция', 'опции', 'опций'], true);

			this.getSources().then(data => {
				this.sources_list = data;
			});

			this.getPartners().then(data => {
				this.partners_list = data;
			});

			this.getTypes('contract_types').then(data => {
				this.contract_types = data;
			});

			if (!this.isEmpty(this.$store.getters.clientsFunnels)) {
				this.funnels = this.$store.getters.clientsFunnels;

				if (!this.isEmpty(this.funnels))
					this.filters.funnel_id = this.funnels[0].value;

				this.goToPage();
			} else {
				this.getFunnels().then(data => {
					this.funnels = data;

					if (!this.isEmpty(this.funnels))
						this.filters.funnel_id = this.funnels[0].value;

					this.goToPage();
				}).catch(function (error) {

					CommonService.log('error', 'getFunnels()::then()', error);
					this.goToPage();
				});
			}

			this.emitter.on("updateClients", () => {
				this.goToPage();
			});

			/*this.$nextTick(() => {
				const item_el = document.getElementById(this.$route.hash);
				if (item_el) {
					item_el && item_el.scrollIntoView();
				}
			});*/

			this.emitter.on('closeFilter', () => this.resetFilter());
        },
		computed: {
			selected_count() {
				return this.selected.length;
			},
			show_reset_filter() {
				return !this.isEmpty(this.search_query);
			},
		},
		watch: {
			items: function (value) {
				if (!CommonService.isEmpty(this.$route.hash)) {
					this.$nextTick(() => {
						CommonService.debounce(async () => {
							const item_el = document.querySelector(this.$route.hash);
							if (item_el) {
								//item_el && item_el.scrollIntoView();
								window.scrollTo({
									behavior: 'smooth',
									top:
										item_el.getBoundingClientRect().top -
										document.body.getBoundingClientRect().top -
										80,
								});
							}
						}, 600);
					});
				}
			},
			filtersCount: function (value) {
				if (value > 0) {
					this.filtersCountLabel = CommonService.declOfNum(value, ['опция', 'опции', 'опций'], true);
				} else {
					this.filtersCountLabel = 'Фильтр';
				}
			}
		}
    };
</script>